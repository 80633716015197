if (document.querySelector('.js-marquee')) {
  const wrapTextTicker = $('.js-marquee')
  const textTicker = $('.js-marquee ').html()
  let speed = 25000

  if (window.innerWidth < 768) {
    speed = 15000
  }

  // for (let i = 0; i < 3; i += 1) {
  //   wrapTextTicker.append(textTicker)
  // }

  $('.js-marquee').marquee({
    // speed in milliseconds of the marquee
    duration: speed,
    // gap in pixels between the tickers
    gap: 0,
    // time in milliseconds before the marquee will start animating
    delayBeforeStart: -18000,
    // 'left' or 'right'
    direction: 'left',
    // true or false - should the marquee be duplicated to show an effect of continues flow
    duplicated: true,
  })
}
