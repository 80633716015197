if (document.querySelector('#news')) {
  fetch('../json/news.json')
  .then((response) => {
    return response.json();
  })
  .then((data) => {    
    data.forEach(elem => {
      console.log(elem);
      document.querySelector('.js-gridNews').insertAdjacentHTML('beforeend', `
      <div class="news-block">
        <div class="news-block__wrap-text">
          <a class="news-block__caption" href="${elem.url}" target="_blank">${elem.title}</a>
          <p class="news-block__text">${elem.text}</p>
        </div>
        <a class="news-block__wrap-img" href="${elem.url}" target="_blank">
          <picture><img src="${elem.img}" alt=""></picture>
        </a>
      </div>`)
    });
    return;
  }).catch((error) => {
    console.log(error)
  });
}