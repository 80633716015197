const arrAccordion = document.querySelectorAll('.js-accordionUp');

arrAccordion.forEach(elem => {
  elem.addEventListener('click', event => {
    const eventTarget = event.target;
    const heightAccContent = eventTarget.nextElementSibling.scrollHeight;
    
    if (eventTarget.classList.contains('is-open')) {
      eventTarget.classList.remove('is-open');
      eventTarget.nextElementSibling.style.maxHeight = `0px`;
    } else {
      eventTarget.classList.add('is-open');
      eventTarget.nextElementSibling.style.maxHeight = `${heightAccContent}px`
    }    
  })
})