const arrVideo = document.querySelectorAll('.js-wrapVideo');

arrVideo.forEach(elem => {
  elem.addEventListener('click', event => {
    const eventTarg = event.target;
    if (eventTarg.classList.contains('js-iconPlay')) {      
      eventTarg.closest('.js-wrapVideo').querySelector('.js-video').play();
      eventTarg.closest('.js-wrapVideo').querySelector('.js-video').controls = true;
      eventTarg.style.display = 'none';
    }
  })
})