if (document.documentElement.clientWidth < 1024) {
  const header = document.querySelector('.js-header')
  const burger = document.querySelector('.js-burger')
  const mobMenu = document.querySelector('.js-mobMenu')

  header.addEventListener('click', (event) => {
    if (event.target.classList.contains('js-burger')) {
      if (mobMenu.classList.contains('is-open')) {
        document.querySelector('html').classList.remove('is-hiden')
        event.target.classList.remove('is-open')
        mobMenu.classList.remove('is-open')
      } else {
        document.querySelector('html').classList.add('is-hiden')
        event.target.classList.add('is-open')
        mobMenu.classList.add('is-open')
      }
    }
    if (event.target.classList.contains('js-navLink')) {
      // event.preventDefault()
      // console.log('fire')
      if (mobMenu.classList.contains('is-open')) {
        mobMenu.classList.remove('is-open')
        burger.classList.remove('is-open')
        document.querySelector('html').classList.remove('is-hiden')
      }
    }
  })
}
