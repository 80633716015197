const clearFields = (elements) => {
  if (elements.closest('.modal-message')) {
    document.querySelectorAll('.form__field, .form__textarea').forEach(elem => {
      elem.value = '';
    })
  }
};


window.addEventListener('click', (event) => {
  const eventTar = event.target;
  
  if (eventTar.dataset.clickModal) {
    event.preventDefault()

    const btnName = eventTar.dataset.clickModal

    document.querySelector('#' + btnName).classList.add('modal--open')
    document.querySelector('html').classList.add('is-hiden')
  }

  if (eventTar.classList.contains('modal__overlay')) {
    clearFields(eventTar)
    eventTar.closest('.modal').classList.remove('modal--open')
    document.querySelector('html').classList.remove('is-hiden')
  } else if (eventTar.hasAttribute('data-close')) {
    clearFields(eventTar)
    eventTar.closest('.modal').classList.remove('modal--open')
    document.querySelector('html').classList.remove('is-hiden')    
  }
})
